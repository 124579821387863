import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function Porsche911() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/PORSCHE-1.webp')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/PORSCHE-1.webp",
        "../../../../monaco_assets/PORSCHE-4.webp",
        "../../../../monaco_assets/PORSCHE-2.webp",
        "../../../../monaco_assets/PORSCHE-5.webp",
        "../../../../monaco_assets/PORSCHE-3.webp",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "How much does it cost to rent porsche 911 ?",
            answer: "At Monaco Experiences, PORSCHE 911 rental prices vary based on the model and rental duration. Contact us for specific pricing details.",
            isCollapsed: true
        },
        {
            id: 1,
            question: "What are the requirements to rent a PORSCHE 911 ?",
            answer: "To rent a PORSCHE 911 , you must be at least 25 years old, possess a valid driver’s license, and carry full insurance coverage. A security deposit is also required at the time of rental.",
            isCollapsed: true
        }
        ,
        {
            id: 2,
            question: "Are there any mileage restrictions when hiring the porsche 911 ?",
            answer: "Yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true
        }
        ,
        {
            id: 3,
            question: "Can the  porsche 911 be delivered to my location?",
            answer: "Yes, we offer a delivery and pick-up service for all the cars to your specified address within our service areas for an additional fee.",
            isCollapsed: true
        }
        ,
        {
            id: 4,
            question: "What happens if the porsche 911 is damaged during my rental period?",
            answer: "In the event of damage, you should immediately report it to us. You may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true
        }
        ,
        {
            id: 5,
            question: "Can I rent a PORSCHE 911 for wedding?",
            answer: "Yes, you can for your wedding with Monaco Experiences. Our stunning vintage cars add timeless elegance and sophistication to your special day. Also, check out our Excalibur.",
            isCollapsed: true
        },
        {
            id: 6,
            question: "Where can I find the porsche 911 rental near me?",
            answer: "Monaco Experiences provides 911 rental services in Los Angeles, New York, Boston, Washington DC,New Jersey, and more. We ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. Contact us to discuss further.",
            isCollapsed: true
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                Porsche 911 Rental in NJ,NY,LA | Monaco Experiences Exotic Car Rental
                </title>
                <meta name="description" content="Rent an Porsche 911 from Monaco Experiences for a high-performance wedding or special occasion. Enjoy power, precision & style. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/porsche-911-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo9.png" alt="Monaco Experiences logo" style={{ height: '35px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>PORSCHE 911 </span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                            show stopper  |  610 horsepower  |  actual msrp : $ 459,900 
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                        porsche 911 rental – luxury & performance for your special day!
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                        if you want to experience the perfect blend of german engineering, luxury, and performance, the 
                        porsche 911 is the ultimate choice. when you rent a porsche 911 from monaco experiences, you're not
                        just driving a sports car—you’re stepping into a legacy of precision, power,
                        and prestige that has defined porsche for decades. the porsche 911 is a masterpiece of design
                        and speed, offering a thrilling yet sophisticated driving experience.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            renting a porsche 911 for a wedding or a luxury event is more than just about getting from one place to another—it's about making a statement. with its sleek, timeless design, high-performance engine, and handcrafted leather interior, the 911 is the perfect wedding car rental for couples who want to arrive in unmatched style. whether it’s a wedding getaway car, a milestone celebration, or a vip event, the porsche 911’s iconic look and exhilarating drive ensure an unforgettable experience.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            when you choose a porsche 911 rental, you're choosing elegance, power, and exclusivity. make your wedding day or luxury event stand out with a car that symbolizes both speed and sophistication. because arriving in a porsche isn’t just about reaching your destination—it’s about making an entrance epic.
                        </p>


                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            live the dream. drive the porsche 911. 🔥
                        </p>
                    </div>
                </div>
            </div>

            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rentals
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury Rental Cars  : Discover Our Unique Showroom Experience
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
