import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function Porsche911() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/urus-1.webp')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/urus-2.webp",
        "../../../../monaco_assets/urus-3.webp",
        "../../../../monaco_assets/urus-4.webp",
        "../../../../monaco_assets/urus-5.webp",
        "../../../../monaco_assets/urus-6.webp",
        "../../../../monaco_assets/urus-7.webp",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "How much does it cost to rent a Lamborghini urus ?",
            answer: "The rental cost varies based on the duration and specifics of your rental agreement. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true
        },
        {
            id: 1,
            question: "What are the requirements to rent a Lamborghini urus?",
            answer: "To rent a Lamborghini urus, you must be at least 25 years old, possess a valid driver’s license, and carry full insurance coverage. A security deposit is also required at the time of rental.",
            isCollapsed: true
        }
        ,
        {
            id: 2,
            question: "Are there any mileage restrictions when hiring the Lamborghini urus?",
            answer: "Yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true
        }
        ,
        {
            id: 3,
            question: "Can the Lamborghini Huracan urus be delivered to my location?",
            answer: "Yes, we offer a delivery and pick-up service for the Lamborghini urus to your specified address within our service areas for an additional fee.",
            isCollapsed: true
        }
        ,
        {
            id: 4,
            question: "What happens if the Lamborghini urus is damaged during my rental period?",
            answer: "In the event of damage, you should immediately report it to us. You may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true
        }
        ,
        {
            id: 5,
            question: "Where can I find Lamborghini rental near me?",
            answer: "Monaco Experiences provides lamborghini rental services in Los Angeles, New York, Boston, Washington DC,New Jersey, and more. We ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. Contact us to discuss further.",
            isCollapsed: true
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                Lamborghini URUS Rental in NJ,NY,LA | Monaco Experiences Exotic Car Rental
                </title>
                <meta name="description" content="Rent an Lamborghini URUS from Monaco Experiences for a high-performance wedding or special occasion. Enjoy power, precision & style. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/lamborghini-urus-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo4.png" alt="Monaco Experiences logo" style={{ height: '35px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>LAMBORGHINI URUS </span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                            4.8l v8  |  650 horsepower  |  actual msrp : $ 241,569 
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                        lamborghini rental is a dream come true for many americans
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                        for those who crave the perfect fusion of supercar performance and SUV versatility, the lamborghini urus is the ultimate choice. at monaco experiences, we bring you the thrill of driving this high-performance luxury SUV, combining raw power, cutting-edge technology, and italian craftsmanship.imagine commanding the streets with 641 horsepower, accelerating from 0 to 60 mph in just 3.5 seconds, all while enjoying the comfort and space of an elite SUV. whether it’s for a special event, a weekend getaway, or simply indulging in the finer things, our lamborghini urus rental offers the perfect balance of luxury and adrenaline.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            our clients rate their urus experience as ‘unmatched’, praising its exhilarating speed, bold design, and premium comfort. our seamless rental process ensures you get behind the wheel effortlessly, allowing you to focus on the thrill of the drive. whether you're making a statement at an event or elevating your daily drive, monaco experiences provides a first-class lamborghini urus rental that guarantees an unforgettable ride. 
                        </p>
    
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            contact us now to reserve your lamborghini and make your next event unforgettable! 🥳
                        </p>
                    </div>
                </div>
            </div>

            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rentals
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury Rental Cars  : Discover Our Unique Showroom Experience
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
